








































































































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchDeleteSession,
} from '@/store/sessions/actions';
import { readAdminSessionsByState } from '@/store/admin/getters';
import { dispatchGetAdminSessions } from '@/store/admin/actions';

@Component
export default class Sessions extends Vue {
  public loading = true;
  public search = '';
  public items = ['active', 'draft', 'complete'];
  public value = ['active', 'draft', 'complete'];
  public state_values = {
    'active': true,
    'draft': true,
    'complete': true,
  }
  public headers = [
    {
      text: 'Status',
      align: 'left',
      width: '50px',
      value: 'status',
      fixed: true,
      show: true,
    },
    {
      text: 'User',
      sortable: true,
      value: 'owner',
      align: 'left',
      width: '200px',
      show: true,
      fixed: true,
    },
    {
      text: this.mobile ? 'Title:' : 'Title',
      sortable: true,
      value: 'title',
      align: 'left',
      width: '400px',
      fixed: true,
      show: true,
    },
    {
      text: this.mobile ? 'Created:' : 'Date created',
      align: 'left',
      value: 'created_date',
      width: '50px',
      fixed: true,
      show: true,
      sortable: true,
    },
    {
      text: this.mobile ? 'Opened / Completed:' : '# Opened / Completed',
      value: 'completes',
      align: 'left',
      width: '50px',
      fixed: true,
      show: true,
    },
  ];

  public rowClick(item, row) {
    row.select(true);
    this.$router.push({
      name: 'main-user-session-view',
      params: { id: item.id },
    });
  }

  public goToSession(incId) {
    this.$router.push({
      name: 'main-user-session-view',
      params: { id: incId },
    });
  }

  public getFormattedDate(date) {
    return new Date(date).toLocaleDateString();
  }

  public getParticipantsStarted(item) {
    return item.p_started ? item.p_started : 0;
  }

  public getParticipantsFinished(item) {
    return item.p_finished ? item.p_finished : 0;
  }

  public goToCreate() {
    this.$router.push({
      name: 'main-session-create',
    });
  }

  public sessions() {
    return readAdminSessionsByState(this.$store)(this.state_values);
  }

  public async deleteSession(id: string) {
    await dispatchDeleteSession(this.$store, id);
  }

  public async mounted() {
    await dispatchGetAdminSessions(this.$store);
    this.loading = false;
  }

  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }

  get buttonSize() {
    return this.$vuetify.breakpoint.mobile ? { ['x-small']: true } : { ['small']: true };
  }

  public removeFilter(item) {
    this.value.splice(this.value.indexOf(item), 1);
  }

  public state_btn_clicked(k) {
    this.state_values[k] = !this.state_values[k];
  }
}
